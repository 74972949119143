.back-drop {
  background: var(--mdss-transparent);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.expandable {
  box-sizing: border-box;
  border-radius: 1rem 1rem 0 0;
  width: 60vw;
  height: 0;
  /* background-color: #fff; */
  /* transition: height 0.2s; */
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 3%;
  left: 50%;
  transform: translate(-50%, 0);
}

.expandable .Mui-selected {
  font-weight: 800;
  background-color: var(--mdss-white);
  color: #000000;
  border: none;
}

.expandable .MuiTabs-indicator {
  background-color: #ffffff00;
}

.expandable .header {
  border-radius: 0.5rem 0.5rem 0 0;
  display: flex;
  flex-direction: column;
}

.expandable-expanded {
  height: 85vh;
}

.expandable .content {
  /* background-color: rgba(0, 0, 0, 0.8); */
  background-color: var(--mdss-grey);
  height: 100%;
  display: flex;
  flex-direction: column;
}

.expandable .content .MuiBox-root {
  padding: 10px 24px;
}

.expandable-expanded .content {
  overflow: auto;
}

.expandable-expanded .content::-webkit-scrollbar {
  display: none;
}

/* 
    visibility
    temperature
    snow accumulation
  
    green #d3f5cf // good
    yellow #fff456 // medium
    purple #ccccfb // bad
    pink #f8b6b2 // worse
   */
