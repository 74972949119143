.road-alerts {
  box-sizing: border-box;
  border-radius: 0.5rem;
  width: 7.5rem;
  height: 12.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: 0.2s;
  cursor: pointer;
}

.road-alerts.default {
  background-color: var(--mdss-blue);
}
.road-alerts.OK {
  background-color: var(--mdss-green);
}
.road-alerts.MARGINAL {
  background-color: var(--mdss-yellow);
}
.road-alerts.POOR {
  background-color: var(--mdss-purple);
}
.road-alerts.EXTREME {
  background-color: var(--mdss-red);
}

.road-alerts h5 {
  margin: 0.8rem 0 0.7rem 0;
}
.road-alerts.default h5 {
  color: #fff;
}

.road-alerts div {
  border: #fff 2px solid;
  border-radius: 0.25rem;
  width: 6rem;
  text-align: center;
  font-size: 1rem;
  margin: 0.3rem 0;
  padding: 0.05rem 0;
}

.road-alerts-default {
  background-color: var(--mdss-blue);
}
.road-alerts-ok {
  background-color: var(--mdss-green);
}
.road-alerts-marginal {
  background-color: var(--mdss-yellow);
}
.road-alerts-poor {
  background-color: var(--mdss-purple);
}
.road-alerts-extreme {
  background-color: var(--mdss-red);
}
