.one-content {
  margin: 0.8rem 0;
}

.weatherDiv {
  text-align: center;
  min-height: 0;
  height: 3rem;
}

.weatherDiv.weather-forecast-OK {
  background-color: var(--mdss-green);
}

.weatherDiv.weather-forecast-MARGINAL {
  background-color: var(--mdss-yellow);
}

.weatherDiv.weather-forecast-POOR {
  background-color: var(--mdss-purple);
}

.weatherDiv.weather-forecast-EXTREME {
  background-color: var(--mdss-red);
}

.weather-forecast-accordion-OK {
  background-color: var(--mdss-green);
}

.weather-forecast-accordion-MARGINAL {
  background-color: var(--mdss-yellow);
}

.weather-forecast-accordion-POOR {
  background-color: var(--mdss-purple);
}

.weather-forecast-accordion-EXTREME {
  background-color: var(--mdss-red);
}

.accordion-one-line {
  display: flex;
  justify-content: space-between;
  font-size: 1.5rem;
  border-bottom: var(--mdss-white) 0.15rem solid;
}

.accordion-one-line:last-child {
  border-bottom: none;
}

.left,
.right {
  /* flex: 1; */
  white-space: nowrap;
  padding: 0 1rem;
  font-weight: 700;
}

.left {
  display: flex;
  justify-content: flex-start;
}

.right {
  display: flex;
  justify-content: flex-end;
}

/* precipitation_type & precipitation_rate need to look like in one group */
.precipitation_type {
  border-bottom: none;
}
/* rate needs to be at center */
/* .Rate div {
  flex: 1;
} */
.rate > div:first-of-type {
  /* justify-content: center; */
  font-weight: 400;
}
