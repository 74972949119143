.drag-area {
  /* margin: 0 auto; */
  border: 2px dashed #fff;
  min-height: 500px;
  width: 100%;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  flex-direction: column;
}

.drag-area.active {
  border: 2px solid #fff;
  opacity: 0.8;
}
.drag-area .icon {
  font-size: 100px;
  color: #fff;
}
.drag-area header {
  font-size: 30px;
  font-weight: 500;
  color: #fff;
}
.drag-area span {
  font-size: 25px;
  font-weight: 500;
  color: #fff;
  margin: 10px 0 15px 0;
}
.drag-area button,
.drag-area-image button {
  padding: 10px 25px;
  font-size: 20px;
  font-weight: 500;
  border: none;
  outline: none;
  background: #fff;
  color: #5256ad;
  border-radius: 5px;
  cursor: pointer;
}

.drag-area-image {
  margin: 0 auto;
  height: 500px;
  /* width: 100%; */
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.5);
}
.drag-area-image img {
  height: 400px;
  width: 100%;
  object-fit: scale-down;
  border-radius: 5px;
}
