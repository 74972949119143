.container {
  display: flex;
  gap: 0.175rem;
  align-items: center;
}

.tagSpan {
  font-weight: 500;
  font-size: 1.25rem;
}
.statusSpan {
  font-weight: 700;
  margin: 0.375rem;
  color: #fff;
  padding: 0.3em 0.5em;
  border-radius: 5px;
  font-size: 1.25rem;
}

@media (max-width: 850px) {
  .tagSpan,
  .statusSpan {
    font-size: 1rem;
  }
}
