:root {
  /* colors for mdss related components */
  --mdss-blue: #3383bd;
  --mdss-grey: #7b7a7add;
  --mdss-green: #ccffcc;
  --mdss-yellow: #fcfe03;
  --mdss-purple: #ccccff;
  --mdss-red: #feb0b2;
  --mdss-white: #ffffff;
  --mdss-transparent: #ffffff00;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
