.upload-data-point-wrapper {
  background-color: rgb(30, 30, 30);
}

.upload-data-point__form-wrapper {
  margin: 0 auto;
  max-width: 960px;
  min-width: 500px;
  background-color: #4a4a4a;
  padding: 1em;
  box-sizing: border-box;
  color: #fff;
}

.textarea {
  background-color: inherit;
  color: inherit;
  width: 95%;
  font-size: 1.5em;
  resize: none;
  height: 4rem;
}

.upload-data-point__form-wrapper > div + div {
  margin-top: 0.775rem;
}
